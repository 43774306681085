import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero'
import SlideUp from '../components/SlideUp'

const ns = `about-page`

const AboutPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <Hero
          hero={{
            urlMobile:
              markdownRemark.frontmatter.hero.hero_image_mobile.publicURL,
            urlDesktop:
              markdownRemark.frontmatter.hero.hero_image_desktop.publicURL,
          }}
        />
        <div className={`${ns}__container`}>
          <SlideUp>
            <h1 className="page--title">{markdownRemark.frontmatter.title}</h1>
          </SlideUp>
          <div className={`${ns}__content-container`}>
            <div className="container-fluid">
              {markdownRemark.frontmatter.founder_message.show_section && (
                <SlideUp>
                  <div className={`${ns}__sect-one row align-items-center`}>
                    <div className="col-12 col-md-4 offset-md-1">
                      <div className="founder-message">
                        <p>
                          {
                            markdownRemark.frontmatter.founder_message
                              .founder_quote
                          }
                        </p>
                        <p className="margin-bottom-0">
                          {
                            markdownRemark.frontmatter.founder_message
                              .founder_attributuion
                          }
                        </p>
                        <p>
                          {
                            markdownRemark.frontmatter.founder_message
                              .organization
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-1">
                      <img
                        style={{ width: '100%' }}
                        className="img-fluid margin-bottom-0"
                        src={
                          markdownRemark.frontmatter.founder_message
                            .founder_image.publicURL
                        }
                        alt=""
                      />
                      <p className="image-attribution-artwork">
                        {
                          markdownRemark.frontmatter.founder_message
                            .founder_image_attributuion
                        }
                      </p>
                    </div>
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.mission_statement && (
                <SlideUp>
                  <div className="row justify-content-center">
                    <div className="col-11">
                      <h2 className="sub-title">OUR MISSION</h2>
                      <h1 className="statment--text">
                        {markdownRemark.frontmatter.mission_statement}
                      </h1>
                    </div>
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.history.show_section && (
                <SlideUp>
                  <div className={`${ns}__history container-fluid`}>
                    <div className="anchor" id="history" />
                    <h2 className="sub-title">
                      {markdownRemark.frontmatter.history.history_heading}
                    </h2>
                    <div className="row">
                      {markdownRemark.frontmatter.history.columns.map(
                        (column, index) => (
                          <div className="col-12 col-md-4" key={index}>
                            <img
                              className="img-fluid"
                              src={column.image.publicURL}
                              alt=""
                            />
                            <p className="year">{column.year} </p>
                            <p className="summary">{column.history_content}</p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.our_story.show_section && (
                <SlideUp>
                  <div className={`${ns}__sections`}>
                    <h2 className="sub-title">
                      {markdownRemark.frontmatter.our_story.our_story_heading}
                    </h2>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {markdownRemark.frontmatter.our_story.our_story_content}
                    </ReactMarkdown>
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.quote.show_section && (
                <SlideUp>
                  <div
                    className={`${ns}__quote row align-items-center no-gutters`}
                  >
                    <div className="col-12 col-md-6 ">
                      <img
                        style={{ marginBottom: '0px' }}
                        className="img-fluid"
                        src={
                          markdownRemark.frontmatter.quote.quote_image.publicURL
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-8 offset-2 col-md-5 offset-md-1 quote-text-container">
                      <p className="quote-text">
                        {markdownRemark.frontmatter.quote.quote}
                      </p>
                      <p className="quote-text">
                        {markdownRemark.frontmatter.quote.quote_attributuion}
                      </p>
                    </div>
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.team.show_section && (
                <SlideUp>
                  <div className={`${ns}__sections ${ns}__sections--team`}>
                    <div className="anchor" id="team" />
                    <h2 className="sub-title">TEAM</h2>
                    <p className="sub-heading">
                      {markdownRemark.frontmatter.team.team_sub_heading}
                    </p>
                    {markdownRemark.frontmatter.team.staff.map(
                      (member, index) => (
                        <p key={index}>{member.staff}</p>
                      )
                    )}
                  </div>
                </SlideUp>
              )}
              {markdownRemark.frontmatter.contact && (
                <SlideUp>
                  <div className={`${ns}__sections contact`}>
                    <div className="anchor" id="contact" />
                    <h2 className="sub-title">
                      {markdownRemark.frontmatter.contact.contact_heading}
                    </h2>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {markdownRemark.frontmatter.contact.contact_content}
                    </ReactMarkdown>
                  </div>
                </SlideUp>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        contact {
          contact_content
          contact_heading
        }
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
        founder_message {
          show_section
          founder_attributuion
          founder_image {
            publicURL
          }
          founder_image_attributuion
          founder_quote
          organization
        }
        mission_statement
        history {
          show_section
          history_heading
          columns {
            history_content
            year
            image {
              publicURL
            }
          }
        }
        our_story {
          show_section
          our_story_content
          our_story_heading
        }
        quote {
          show_section
          quote
          quote_attributuion
          quote_image {
            publicURL
          }
        }
        team {
          show_section
          staff {
            staff
          }
          team_heading
          team_image {
            publicURL
          }
          team_image_attributuion
          team_sub_heading
        }
      }
    }
  }
`
