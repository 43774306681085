import React, { useRef, useLayoutEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const ns = `slide-up`

const SlideUp = ({ children, animation }) => {
  // target
  const targetElement = useRef()

  useLayoutEffect(() => {
    const target = targetElement.current
    animation(target)
  }, [animation])

  return (
    <div ref={targetElement} className={`${ns}`}>
      {children}
    </div>
  )
}

SlideUp.defaultProps = {
  animation: target => {
    gsap.set(target, {
      y: 50,
      opacity: 0,
      transition: 'all 150ms linear',
    })

    gsap.to(target, {
      scrollTrigger: {
        start: 'top+=90 bottom',
        trigger: target,
        toggleActions: 'play none none reset',
      },
      y: 0,
      opacity: 1,
      duration: 0.15,
      transition: 'all 150ms linear',
    })
  },
}

export default SlideUp
